import React, { useState } from 'react';
import logo from '../img/salon-91-logo.png'
import bgImg from '../img/hair-spray-1.jpg'
import Nav from '../components/nav';
import Footer from '../components/footer';

//import { BrowserRouter, Route} from 'react-router-dom';

//import { createClient } from "@sanity/client"
//import NewIdea from '../home/newIdea';

// export const client = createClient({
//   projectId: "y12zu0ze", 
//   dataset: "production", 
//   apiVersion: "2024-03-11",
//   // Set to `true` for production environments
//   useCdn: false, 
// })


function Services() {

  const [menuOpen, setMenu] = useState(false);

  function openMenu() {
    setMenu(true);
  }

  function closeMenu() {
    setMenu(false);
  }


  return (
    <div className="">
      {/* <header className="absolute inset-x-0 top-0 z-50">
      <Nav />      
      </header> */}

      <div >

        <header class="absolute inset-x-0 top-0 z-50">
          <div class="mx-auto max-w-7xl">
            <div class="px-6 pt-6 lg:max-w-2xl lg:pl-8 lg:pr-0">
              <nav class="flex items-center justify-between lg:justify-start" aria-label="Global">
                <a href="#" class="-m-1.5 p-1.5">
                  <span class="sr-only">Your Company</span>
                  <img className="w-auto m-auto" src={logo} alt="" />
                  {/* <img alt="Your Company" class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" /> */}
                </a>
                <button type="button" onClick={openMenu} class="-m-2.5 rounded-md p-2.5 text-gray-700 lg:hidden">
                  <span class="sr-only">Open main menu</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>
                <div class="hidden lg:ml-12 lg:flex lg:gap-x-14">
                  <a href="/" class="text-sm font-semibold leading-6 text-gray-900">Home</a>
                  <a href="/services" class="text-sm font-semibold leading-6 text-gray-900">Services</a>
                  {/* <a href="/products" class="text-sm font-semibold leading-6 text-gray-900">Products</a> */}
                  <a href="/contact" class="text-sm font-semibold leading-6 text-gray-900">Contact</a>
                </div>
              </nav>
            </div>
          </div>

          {menuOpen && (
            <div class="lg:hidden" role="dialog" aria-modal="true">
              {/* <!-- Background backdrop, show/hide based on slide-over state. --> */}
              <div class="fixed inset-0 z-50"></div>
              <div class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="flex items-center justify-between">
                  <a href="#" class="-m-1.5 p-1.5">
                    <span class="sr-only">Your Company</span>
                    <img class="h-8 w-auto" src={logo} alt="" />
                  </a>
                  <button type="button" onClick={closeMenu} class="-m-2.5 rounded-md p-2.5 text-gray-700">
                    <span class="sr-only">Close menu</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div class="mt-6 flow-root">
                  <div class="-my-6 divide-y divide-gray-400/10">
                    <div class="space-y-2 py-6">
                      <a href="/" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Home</a>
                      <a href="/services" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Services</a>
                      {/* <a href="/products" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Products</a> */}
                      <a href="/contact" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Contact</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}
        </header>

        <div class="relative">

          <div class="mx-auto max-w-7xl">
            <div class="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
              <svg class="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                <polygon points="0,0 90,0 50,100 0,100" />
              </svg>

              <div class="relative px-6 pb-6 lg:px-8 lg:pr-0">
                <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                  {/* <div class="hidden sm:mb-10 sm:flex">
              <div class="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Anim aute id magna aliqua ad ad non deserunt sunt. <a href="#" class="whitespace-nowrap font-semibold text-indigo-600"><span class="absolute inset-0" aria-hidden="true"></span>Read more <span aria-hidden="true">&rarr;</span></a>
              </div>
            </div> */}
                  <h1 class="mt-24 text-3xl tracking-tight text-gray-900 sm:text-4xl lato">SERVICE MENU</h1>
                  {/* <p class="mt-4 text-lg leading-8 text-gray-600">A premier Tulsa salon</p> */}

                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img class="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full" src={bgImg} alt="" />
            {/* <div class="absolute inset-0 bg-gray-600 mix-blend-multiply" aria-hidden="true"></div> */}
          </div>
        </div>
      </div>


      <div class="p-4 lg:p-12">

       

        <div class="grid grid-cols-3 gap-8">
          
          <div class="row-span-1 lg:row-span-3 col-span-3 lg:col-span-1"> 
            <div class="p-10 bg-gray-200">
              <h2 class="text-2xl font-bold pb-2">Color</h2>
              <ul role="list" class="divide-y divide-gray-400">
                <li class="flex justify-between py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">All Over Color</p>
                    </div>
                  </div>
                  <div class="shrink-0 sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$40 - $110</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Balayage</p>
                    </div>
                  </div>
                  <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$135+</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Partial Foil</p>
                    </div>
                  </div>
                  <div class=" shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$60 - $165</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Partial Foil with Color</p>
                    </div>
                  </div>
                  <div class=" shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$80 - $185</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Full Foil</p>
                    </div>
                  </div>
                  <div class=" shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$100 - $185</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Full Foil with Color</p>
                    </div>
                  </div>
                  <div class=" shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$100 - $205</p>
                  </div>
                </li>

                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Men's Highlights</p>
                    </div>
                  </div>
                  <div class=" shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$55</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Men's Reshade</p>
                    </div>
                  </div>
                  <div class=" shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$40</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>


           <div class="row-span-1 col-span-3 lg:col-span-1">
            <div class="p-10  bg-gray-200">
              <h2 class="text-2xl font-bold pb-2">Cut</h2>
              <ul role="list" class="divide-y divide-gray-400">
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Women</p>
                    </div>
                  </div>
                  <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$40 - $110</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Men</p>
                    </div>
                  </div>
                  <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$25 - $85</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Children</p>
                    </div>
                  </div>
                  <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$25 - $35</p>
                  </div>
                </li>
              </ul>
            </div>

          </div>
         <div class="row-span-1 col-span-3 lg:col-span-1">

            <div class="p-10 bg-gray-200">
              <h2 class="text-2xl font-bold pb-2">Style</h2>
              <ul role="list" class="divide-y divide-gray-400">
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Blowout</p>
                    </div>
                  </div>
                  <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$35 - $75</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Updo Style</p>
                    </div>
                  </div>
                  <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$75+</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          
          <div class="row-span-1 col-span-3 lg:col-span-1">
            <div class="p-10 bg-gray-200" >
              <h2 class="text-2xl font-bold pb-2">TREATMENT</h2>
              <ul role="list" class="divide-y divide-gray-400">
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Brazilian Blowout</p>
                    </div>
                  </div>
                  <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$300+</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Brazilian Blowout Express</p>
                    </div>
                  </div>
                  <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$150+</p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-5">
                  <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6 text-gray-900">Perm</p>
                    </div>
                  </div>
                  <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6 text-gray-900">$105+</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="row-span-1 col-span-3 lg:col-span-1">
          <div class="p-10 bg-gray-200" >
          <h2 class="text-2xl font-bold pb-2">EXTENSIONS</h2>
          <ul role="list" class="divide-y divide-gray-400">
            <li class="gap-x-12 py-5">
              <p class="text-sm font-semibold leading-6 text-gray-900">Tape-In or Hand-tied</p>
              <p class="text-sm leading-6 text-gray-900">Price quoted upon consultation.</p>

            </li>

          </ul>
        </div>
          </div>
        </div>


<div class="mt-6 flex justify-center">

<a href="/contact" class="rounded-md 
                                            bg-red-700 px-3.5 py-2.5 text-xl text-white shadow-sm 
                                            hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
                                            focus-visible:outline-offset-2 focus-visible:outline-indigo-900">Schedule Appointment</a>
</div>

       


      </div>

      <Footer />
    </div>
  );
}

export default Services;