import React, { useState } from 'react';
import logo from '../img/salon-91-logo.png'
import bgImg from '../img/hair-spray-1.jpg'
import bgImg2 from '../img/hero-2-small.jpg'
import bb from '../img/brazilian_blowout.jpg'
import mensCut from '../img/mens-cut.jpg'
import km1 from '../img/km1.jpg'
import eleven from '../img/eleven.png'
import productImg from '../img/products.png'
import Nav from '../components/nav';
import Hero from '../components/hero';
import Footer from '../components/footer';
import Melissa from '../img/team/melissa-avatar.png';
// import Solutions from '../home/solutions';
// import Posts from '../blog/posts';

import { BrowserRouter, Route } from 'react-router-dom';

// import { createClient } from "@sanity/client"
// import NewIdea from '../home/newIdea';

// export const client = createClient({
//   projectId: "y12zu0ze", 
//   dataset: "production", 
//   apiVersion: "2024-03-11",
//   // Set to `true` for production environments
//   useCdn: false, 
// })


function Home() {

    return (
        <div className="App">
            <header className="absolute inset-x-0 top-0 z-50">
                <Nav />
            </header>

            <div class="bg-white">

                <div class="relative bg-gray-800 py-16 sm:py-16">
                    <div class="absolute inset-0">
                        <img class="h-full w-full object-cover"
                            src={bgImg}

                            alt="" />
                        <div class="absolute inset-0 bg-gray-600 mix-blend-multiply" aria-hidden="true"></div>
                    </div>
                    <div class="relative max-w-7xl px-6 pt-12 lg:px-8">
                        {/* <img className="w-auto m-auto" src={logo} alt="" /> */}
                        <h1 class="text-2xl mt-8 tracking-tight text-white md:text-3xl lg:text-4xl">Schedule today</h1>
                        {/* Create Your Best Look */}
                        {/* <p class="mt-8 text-md text-gray-300">Our stylists stay up to date on the latest styles <br /> and techniques to bring you the look you want. </p> */}
                    </div>
                </div>


                <div class="mt-6 overflow-hidden sm:mt-6 pb-20">

                    <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">

                        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">

                            <div class="lg:w-full lg:p-8 lg:pl-24">

                                <div class="sm:text-center md:mx-auto md:max-w-2xl lg:col-span-lg:text-left">
                                    <h1>
                                        <span class="block text-base font-semibold text-gray-500 sm:text-lg lg:text-base xl:text-lg">Stylists Available</span>
                                        <span class="mt-1 block text-2xl font-bold tracking-tight sm:text-xl xl:text-2xl">
                                            <span class="block text-gray-900">Mon-Sat &nbsp;&nbsp; 10am - 7pm</span>
                                            {/* <span class="block text-indigo-600">online business</span> */}
                                        </span>
                                    </h1>
                                    {/* <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">To schedule, enter your <b>phone number</b> or <b>email</b> below.</p> */}
                                    <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Call now to schedule!</p>
                                    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"><a href="tel:9184961412"><u>918-496-1412</u></a></p>
                                    <div class="sm:mx-auto sm:max-w-lg sm:text-center lg:mx-0 lg:text-left">
                                        
                                        {/* <p class="text-base font-medium text-gray-900">You'll get notified upon confirmation.</p> */}
                                        {/* <form action="#" method="POST" class="mt-3 sm:flex">
                                            <label for="email" class="sr-only">Email</label>
                                            <input type="email" name="email" id="email" class="block w-full rounded-md border-gray-300 py-3 text-base placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:flex-1" placeholder="Enter your email or phone" />
                                            <button type="submit" class="mt-3 w-full rounded-md border border-transparent bg-gray-800 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:inline-flex sm:w-auto sm:flex-shrink-0 sm:items-center">Notify me</button>
                                        </form> */}
                                    
                                        <br />
                                        <div class="">
                                            {/* <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Stylists</h2> */}
                                            <ul role="list" class="">
                                                <li>
                                                    <br />
                                                    <div class="flex items-center gap-x-6">
                                                        <img class="h-16 w-16 rounded-full" src={Melissa} alt="" />
                                                        <div class="gap-x-6">
                                                            <h3 class="text-xl font-semibold leading-7 tracking-tight text-gray-900">Melissa</h3>
                                                            <p>Melissa has been a Master Colorist as Salon 91 for the past 12 years and has 15 years of experience. Melissa is trained and certified in multiple color systems and enjoys using her knowledge to create exactly what her clients are envisioning when they sit down in the chair. Melissa has been Brazilian Blowout certified for 11 years and would love to help you reach your goals! </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <br />
                                        <hr />
                                        <br />
                                        <a href="/services" class="rounded-md mx-auto 
                                            bg-red-700 px-3.5 py-2.5 text-xl text-white shadow-sm 
                                            hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
                                            focus-visible:outline-offset-2 focus-visible:outline-indigo-900">View All Services</a>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                                <div class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-start">
                                {/* <img src={bb} alt="" class="aspect-[3/4] w-[27rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" /> */}
                                    <img src={bgImg2} alt="" class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
                                </div>
                                <div class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                                    {/* <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                                    <img src={mensCut} alt="" class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />                                           
                                    </div>
                                    <div class="flex w-46 flex-auto justify-end lg:w-auto lg:flex-none">
                                    <img src={eleven} alt="" class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
                                    
                                    <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                                        <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">See our full line of products here.</p>
                                        
                                        <img src={km1} alt="" class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="bg-gray-900 py-16 sm:py-24 lg:py-32">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
                        <div className="max-w-xl text-3xl font-bold tracking-tight text-white sm:text-4xl lg:col-span-7">
                            <h2 className="inline sm:block lg:inline xl:block">Sign up for new product drops</h2>
                        </div>
                        <form className="w-full max-w-md lg:col-span-5 lg:pt-2">
                            <div className="flex gap-x-4">
                                <label htmlFor="email-address" className="sr-only">Email address</label>
                                <input id="email-address" name="email" type="email" autoComplete="email" required className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your email" />
                                <button type="submit" className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Subscribe</button>
                            </div>
                        </form>
                    </div>
                </div> */}


            </div>
            <Footer />
        </div>
        
    );
}

export default Home;