import React, { useState } from 'react';
import logo from '../img/salon-91-logo.png'
import bgImg from '../img/hair-spray-1.jpg'
import map from '../img/map.png'
import Nav from '../components/nav';
import Hero from '../components/hero';

//import { BrowserRouter, Route} from 'react-router-dom';

//import { createClient } from "@sanity/client"
//import NewIdea from '../home/newIdea';

// export const client = createClient({
//   projectId: "y12zu0ze", 
//   dataset: "production", 
//   apiVersion: "2024-03-11",
//   // Set to `true` for production environments
//   useCdn: false, 
// })


function Home() {
  const [menuOpen, setMenu] = useState(false);

  function openMenu() {
    setMenu(true);
  }

  function closeMenu() {
    setMenu(false);
  }
  return (
    <div className="App">
      {/* <header className="absolute inset-x-0 top-0 z-50">
      <Nav />      
      </header> */}

      <div class="bg-white">
        <header class="absolute inset-x-0 top-0 z-50">
          <div class="mx-auto max-w-7xl">
            <div class="px-6 pt-6 lg:max-w-2xl lg:pl-8 lg:pr-0">
              <nav class="flex items-center justify-between lg:justify-start" aria-label="Global">
                <a href="/" class="-m-1.5 p-1.5">
                  <span class="sr-only">Salon 91</span>
                  <img className="w-auto m-auto" src={logo} alt="" />
                  {/* <img alt="Your Company" class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" /> */}
                </a>
                <button type="button" onClick={openMenu} class="-m-2.5 rounded-md p-2.5 text-gray-700 lg:hidden">
                  <span class="sr-only">Open main menu</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>
                <div class="hidden lg:ml-12 lg:flex lg:gap-x-14">
                  <a href="/" class="text-md font-semibold leading-6 text-gray-900">Home</a>
                  <a href="/services" class="text-md font-semibold leading-6 text-gray-900">Services</a>
                  {/* <a href="/products" class="text-sm font-semibold leading-6 text-gray-900">Products</a> */}
                  <a href="/contact" class="text-md font-semibold leading-6 text-gray-900">Contact</a>
                </div>
              </nav>
            </div>
          </div>
          {menuOpen && (
            <div class="lg:hidden" role="dialog" aria-modal="true">
              {/* <!-- Background backdrop, show/hide based on slide-over state. --> */}
              <div class="fixed inset-0 z-50"></div>
              <div class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="flex items-center justify-between">
                  <a href="#" class="-m-1.5 p-1.5">
                    <span class="sr-only">Salon 91</span>
                    <img class="h-8 w-auto" src={logo} alt="" />
                  </a>
                  <button type="button" onClick={closeMenu} class="-m-2.5 rounded-md p-2.5 text-gray-700">
                    <span class="sr-only">Close menu</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div class="mt-6 flow-root">
                  <div class="-my-6 divide-y divide-gray-500/10">
                    <div class="space-y-2 py-6">
                      <a href="/" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Home</a>
                      <a href="/services" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Services</a>
                      {/* <a href="/products" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Products</a> */}
                      <a href="/contact" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Contact</a>
                    </div>
                    <div class="py-6">
                      {/* <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </header>

        <div class="relative">
          <div class="mx-auto max-w-7xl">
            <div class="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
              <svg class="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                <polygon points="0,0 90,0 50,100 0,100" />
              </svg>

              <div class="relative px-6 pb-6 lg:px-8 lg:pr-0">
                <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                  <h1 class="mt-24 text-4xl tracking-tight text-gray-900 sm:text-6xl lato">Contact</h1>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img class="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full" src={bgImg} alt="" />
            {/* <div class="absolute inset-0 bg-gray-600 mix-blend-multiply" aria-hidden="true"></div> */}
          </div>
        </div>
      </div>

      <div class="bg-white py-4 sm:py-12">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">

          <div class="mx-auto grid grid-cols-1 gap-x-8 gap-y-6 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div>
              <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"><a href="tel:9184961412"><u>918-496-1412</u></a></p>
              <br />
              <iframe src="https://tally.so/embed/wAJQAe?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" loading="lazy" width="100%" height="676" title="Contact form"></iframe>
            </div>
            <div class="col-span-2 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
              <div class="relative">
              <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Hours</p>
              <h2 class="text-base font-semibold leading-7 text-indigo-600">Monday - Saturday: 11am - 7pm</h2>
              <h2 class="text-base font-semibold leading-7 text-indigo-600">Sunday: Closed</h2>

              <br />
              <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Address</h2>
              <h2 class="text-base font-semibold leading-7 text-indigo-600">8988 S Sheridan Rd Suite X, Tulsa, Ok. 74133 (91st & Sheridan, NW Corner)</h2>
              <br />
              <img src={map} style={{width:400, margin:'0 auto'}} />
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4024.7172839245236!2d-95.90815512346343!3d36.03260657247603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b6920226c94327%3A0x63c01e095d53134a!2sSalon%2091!5e1!3m2!1sen!2sus!4v1718496085597!5m2!1sen!2sus"
                width="350" height="350" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}



              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Home;