import React, { useState } from 'react';
import logo from '../img/salon-91-logo.png'
// import logoDark from '../img/hurdle-it-logo-dark.png'
//import { RedirectToUserProfile, SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";


function Nav() {

    const [menuOpen, setMenu] = useState(false);

    function openMenu() {
      setMenu(true);
  }
  
  function closeMenu() {
      setMenu(false);
  }

    return (
<div>
        <nav className="flex items-center justify-between lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1 justify-center">
            <a href="/" className="m-1.5 p-1.5">
              <span className="sr-only">Salon 91</span>
              <img className="w-auto" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden p-4">
            <button type="button" onClick={openMenu} className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-100">
              <span className="sr-only">Open main menu</span>
              <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-16">
            <a href="/" className="text-md leading-6 text-white">Home</a>
            <a href="/services" className="text-md leading-6 text-white">Services</a>
            {/* <a href="/products" className="text-md leading-6 text-white">Products</a> */}
            <a href="/contact" className="text-md leading-6 text-white">Contact</a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {/* <SignedOut>
                      <SignInButton />
                    </SignedOut>
                    <SignedIn>
                    <RedirectToUserProfile/>
                      <UserButton />
                    </SignedIn> */}
            {/* <a href="/authenticate" className="text-sm font-semibold leading-6 text-white">Log in <span aria-hidden="true">&rarr;</span></a> */}
            <a href="tel:9189229194" className="block text-white">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 relative text-white mt-3">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
            </svg>
            </a>
            <a href="tel:9184964142" className="block px-3 py-2.5 text-base font-semibold leading-7 text-white">
              <span className="relative">
              918-496-1412
              </span>
            </a>

          </div>
        </nav>
        {menuOpen && (
          <div className="lg:hidden" role="dialog" aria-modal="true">
            {/* <!-- Background backdrop, show/hide based on slide-over state. --> */}
            <div className="fixed inset-0 z-50"></div>
            <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Hurdle IT</span>
                  <img className="h-8 w-auto" src={logo} alt="" />
                </a>
                <button type="button" onClick={closeMenu} className="-m-2.5 rounded-md p-2.5 text-gray-700">
                  <span className="sr-only">Close menu</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <a href="/home" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Home</a>
                    <a href="/services" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Services</a>
                    {/* <a href="/products" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Products</a> */}
                    <a href="/contact" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Contact</a>
                  </div>
                  <div className="py-6">
                    {/* <a href="/authenticate" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a> */}
                    <div className="flex justify-center" >
                    <a href="tel:9189229194" className="relative">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 relative mt-3">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                      </svg>
                      </a>
                      <a href="tel:9184961412" className="text-center block px-3 py-2.5 font-semibold leading-7">
                        <span className="relative">
                        918-496-1412
                        </span>
                      </a>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );

}

export default Nav;
